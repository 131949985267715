import { makeAutoObservable } from "mobx";
import axios from "axios";
import store from "store"
import instance from "../../../store/axios";
import loaderController from "../../template/loader/loader.controller"


const HOST = process.env.REACT_APP_HOST;
const HOST_SETTING = process.env.REACT_APP_HOST_SETTING;
const APIKEY = process.env.REACT_APP_APIKEY;

class authorizationController {

    constructor() {
        makeAutoObservable(this)
    }

    data = {
        email: "",
        password: ""
    }

    error = ""

    onChangeData(key, value) {
        this.data[key] = value
    }

    getInfo(bandle) {
        axios({
            method: "get",
            url: HOST_SETTING + "/admin/get/" + bandle,
            headers: {
                "Authorizations": APIKEY,
                "bandl": store.get("project") && store.get("project").bandl !== "" ? store.get("project").bandl : "",
                "Language": store.get("lang") && store.get("lang") ? store.get("lang") : "Ru",
                "Type-Device": "react_" + bandle,
            }
        }).then(success => {
            console.log(success)
            let url = success.data.link.replace(/v1/, "panel");
            if (url[url.length-1] === "/") {
                url = url.slice(0, url.length-1);
            }
            instance.defaults.baseURL = url;
            console.log(url);

            store.set("project", {
                bandl: bandle,
                baseURL: url,
                android: success.data.urlandroid,
                ios: success.data.urlios
            });
            document.location.href="/";
        });

    }

    onSubmitForm(e) {
        e.preventDefault()

        loaderController.onChange(true)

        let facts = new FormData()
        facts.append("login", this.data.email)
        facts.append("password", this.data.password)

        console.log(store.get("lang"))

        axios({
            method: "post",
            url: HOST + "/user/auth/",
            data: facts,
            headers: {
                "Authorizations": APIKEY,
                "Language": store.get("lang") ? store.get("lang") : "Ru",
            }
        }).then(success => {
            store.set('user', success.data);

            if(success.data.bandl !== "" && success.data.bandl !== null) {
                store.set("project", {
                    bandl: success.data.bandl
                });
                this.getInfo(success.data.bandl);
            } else {
                document.location.href="/";
            }
        })
        .catch(error => {
            console.log(error.response)
            this.error = error.response.data ?? ''
            loaderController.onChange(false)
        });
    }

}

export default new authorizationController()