import {observer} from "mobx-react-lite";
import {Layout, Menu, Row, Col, Space} from "antd";
import {DashboardOutlined, AppstoreAddOutlined, ShopOutlined, InstagramOutlined, UserOutlined, SnippetsOutlined, BellOutlined, MenuOutlined, CloseOutlined} from "@ant-design/icons";
import store from "store"
import headerController from "./controllers/header.controller"
import "./header.less"
import logotype from "../../../images/saleskit.svg"
import React, {useEffect} from "react";
import userIcon from "./images/user.svg"

import {
    Link
} from "react-router-dom";
import TemplateSkeleton from "../skeletons/TemplateSkeleton";

const { Sider } = Layout;
const { SubMenu } = Menu;
const nowPath = window.location.pathname;
const url = nowPath.split("/");

const HeaderTop = () => {

    return(
        <>
            <Row className="top" justify="space-around" align="middle">
                <Col
                    xs={{ span: 4 }}
                    md={{ span: 0 }}
                >
                    <button
                        onClick={() => headerController.onChangeCollapsed()}
                        className="button-header"
                    >
                        {headerController.collapsed ? <CloseOutlined /> : <MenuOutlined />}
                    </button>
                </Col>
                <Col
                    xs={{ span: 16 }}
                    md={{ span: 0 }}
                >
                    <div className="logo">
                        <Link to="/"><img src={logotype} alt=""/></Link>
                    </div>
                </Col>
                <Col
                    xs={{ span: 0 }}
                    md={{ span: 17 }}
                >
                    <form onSubmit={e => headerController.getToSearch(e)}>
                        <input
                            type="text"
                            onChange={e => headerController.onChangeSearchValue(e.target.value)}
                            placeholder="Поиск товаров"
                        />
                    </form>
                </Col>
                <Col xs={{ span: 0 }} md={{ span: 7 }} align="right">
                    <Space>
                        <div>
                            <p className="name">{store.get("user").firstname && store.get("user").firstname !== ""
                                ? store.get("user").firstname + " " + store.get("user").lastname
                                : store.get("user").login
                            }</p>
                            <a href="/logout">Выйти</a>
                        </div>

                        <img src={userIcon} className="user_name" alt=""/>
                    </Space>

                </Col>
                <Col xs={{ span: 4 }} md={{ span: 0 }} align="right">
                    <Link
                        to="/user"
                        className="button-header"
                    >
                        <UserOutlined />
                    </Link>
                </Col>
            </Row>
        </>
    )
}

const moderator = store.get("user") && store.get("user").moderator

const listMenu = [
    { key: "dashboard", link: "/", name: "Рабочий стол", icon: <DashboardOutlined />, vis: true },
    {
        key: "shop",
        multilevel: true,
        icon: <ShopOutlined />,
        name: "Мой магазин",
        vis: true,
        items: [
            { key: "catalog", link: "/shop/catalog", name: "Каталог", vis: !moderator },
            { key: "search", link: "/shop/search", name: "Товары", vis: !moderator },
            { key: "moderation", link: "/shop/moderation", name: "Модерация товаров", vis: true },
            { key: "status", link: "/shop/status", name: "Статусы товаров", vis: !moderator },
            { key: "properties", link: "/shop/properties", name: "Фильтры", vis: !moderator },
            // { key: "import", link: "/shop/import", name: "Импорт каталога" },
            { key: "orders", link: "/shop/orders", name: "Заказы", vis: !moderator },
            { key: "statuses", link: "/shop/statuses", name: "Статусы заказов", vis: !moderator },
            { key: "points", link: "/shop/points", name: "Филиалы", vis: !moderator },
            { key: "delivery", link: "/shop/delivery", name: "Доставка", vis: !moderator }
        ]
    },
    { key: "main", link: "/main", name: "Главный экран", icon: <AppstoreAddOutlined />, vis: !moderator },
    { key: "users", link: "/users", name: "Пользователи", icon: <UserOutlined />, vis: !moderator },
    { key: "client_statuses", link: "/client_statuses", name: "Статусы пользователей", icon: <UserOutlined />, vis: !moderator },
    { key: "pages", link: "/pages", name: "Страницы", icon: <SnippetsOutlined />, vis: !moderator },
    {
        key: "news_list",
        multilevel: true,
        icon: <BellOutlined />,
        name: "Новости",
        vis: true,
        items: [
            { key: "news", link: "/news", name: "Список новостей", vis: true },
            { key: "type", link: "/news/type", name: "Типы новостей", vis: true }
        ]
    },
    { key: "contacts", link: "/contacts", name: "Контакты", icon: <InstagramOutlined />, vis: !moderator },
]

const HeaderUser = observer(({ content }) => {

    useEffect(() => {

        if(url[1] && url[1] !== "") {
            if(url[1] === "news") {
                headerController.setMenu("menuTop", "news-list")
            } else {
                headerController.setMenu("menuTop", url[1])
            }
        }

        if(url[3] !== "" && isNaN(url[3]) === true && url[3] !== undefined) {
            headerController.setMenu("menuLevel", url[3])
        } else if(url[2] !== "" && isNaN(url[2]) === true && url[2] !== undefined) {
            if(url[1] === "shop" || url[1] === "news") {
                if(url[1] === "news") {
                    if(url[2] === "type") {
                        headerController.setMenu("menuLevel", "type")
                    }
                } else {
                    headerController.setMenu("menuLevel", url[2])
                }
            } else {
                if(url[1] !== "") {
                    headerController.setMenu("menuLevel", url[1])
                } else {
                    headerController.setMenu("menuLevel", "dashboard")
                }
            }
        } else {
            if(url[1] !== "") {
                headerController.setMenu("menuLevel", url[1])
            } else {
                headerController.setMenu("menuLevel", "dashboard")
            }
        }
    }, [url]);

    const navigationMobile =
        <ul>
            {listMenu.filter(el => el.vis).map(item => item.vis ?
                <li>
                    {item.multilevel
                        ?
                            <>
                                <ul key={item.key}>
                                    <a href="#">{item.name}</a>
                                    {item.items.filter(e => e.vis).map(el =>
                                        <li>
                                            <Link to={el.link} key={el.key} onClick={() => headerController.setMenu("menuLevel", el.key)}>
                                                {el.name}
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            </>
                        :
                            <>
                                <Link to={item.link} key={item.key} onClick={() => headerController.setMenu("menuTop", item.key)}>{item.name}</Link>
                            </>
                    }
                </li> : <></>
            )}
        </ul>

    const navigation =
        <Menu
            selectedKeys={[headerController.menuTop, headerController.menuLevel]}
            openKeys={[headerController.menuTop]}
            onOpenChange={e => headerController.setMenu("menuTop", e[1])}
            mode="inline"
        >
            {listMenu.filter(el => el.vis).map(item =>
                <>
                    {item.multilevel
                        ?
                            <>
                                <SubMenu key={item.key} icon={item.icon} title={item.name}>
                                    {item.items.filter(e => e.vis).map(el =>
                                        <Menu.Item key={el.key}>
                                            <Link to={el.link} onClick={() => headerController.setMenu("menuLevel", el.key)}>
                                                {el.name}
                                            </Link>
                                        </Menu.Item>
                                    )}
                                </SubMenu>
                            </>
                        :
                            <>
                                <Menu.Item key={item.key} icon={item.icon}>
                                    <Link to={item.link} onClick={() => headerController.setMenu("menuTop", item.key)}>{item.name}</Link>
                                </Menu.Item>
                            </>
                    }
                </>
            )}
        </Menu>

    const AuthYes =
        <>
            <Layout className="header_layout" style={{ minHeight: '100vh' }}>
                <Sider
                    style={{
                        overflowX: 'hidden',
                        height: '100vh',
                        position: 'fixed',
                        left: 0,
                    }}
                    // breakpoint={{
                    //     xs: '480px',
                    //     sm: '576px',
                    //     md: '768px',
                    //     lg: '992px',
                    //     xl: '1200px',
                    //     xxl: '250px'
                    // }}
                    className="header_slider"
                    collapsed={headerController.collapsed}
                    onCollapse={e => headerController.onChangeCollapsed(e)}
                >
                    <div className="logo">
                        <a href="/"><img src={logotype} alt=""/></a>
                    </div>
                    {/*<div className="create_product">*/}
                    {/*    <a href="#">Добавить товар</a>*/}
                    {/*</div>*/}
                    {navigation}
                </Sider>
                <Layout className="site-layout">
                    <HeaderTop />
                    {headerController.collapsed &&
                        <div className="mobile-menu">
                            {navigationMobile}
                        </div>
                    }
                    <div className="template-content mobile-padding">{content}<TemplateSkeleton /></div>
                </Layout>
            </Layout>


        </>

    const AuthNo = content

    return store.get("user") && store.get("user").bandl && store.get("user").bandl !== "" ? AuthYes : AuthNo
})

export default HeaderUser